<script>
import Base from '@backend/Base.vue';
import BOGen from '@helper/BOGen';

export default {
    extends: Base,
    data(){
        return {
            Name:"BoDashboard",
            NoSearch: true,
            AppName: "",
        }
    },
    mounted(){
        this.$set(this.$root, 'page', this)
        this.refreshData()
        this.AppName = Page.web.AppName
    },
    methods:{
    }
};
</script>

<template>
  <div class="container-fluid">
		<PageTitle></PageTitle>
        <div class="panel panel-flat" v-if="!$route.params.id">
            <div class="panel-body">
                <!-- <h3 class="box-title">Dashboard</h3> -->
                <div class="row">
                    <div class="col-sm-12">
                        <p class="mb15">Howdy <strong>{{ user.bu_full_name }}</strong>,
                            <br> Selamat datang di Halaman Administrator <strong>{{ AppName }}</strong>!. </p>
                        <p class="mb15">
                            Anda berada di halaman TERPROTEKSI sistem Pengaturan Konten Website
                            <strong>{{ AppName }}</strong>.
                            <br>
                            <br> Halaman ini khusus diperuntukkan HANYA untuk Administrator website yang akan melakukan pengelolaan konten.
                            <br> Sebelum melakukan pengaturan, beberapa hal yang perlu diperhatikan :
                            <br>
                            <br> 1. Simpan dan amankan Username dan Password Anda untuk menghindari hal-hal yang tidak diinginkan.
                            <br> 2. Siapkan dahulu materi /bahan yang akan digunakan dalam pengelolaan konten yang akan di update.
                            <br> 3. Siapkan pula foto, video atau material lain yang terkait, untuk memudahkan proses pengupdate-an.
                            <br> 4. Gunakan Browser dengan versi terbaru untuk mendapatkan compatibilitas fitur-fitur dalam backoffice ini. </p>
                        <br>
                        <br>
                        <br>
                        <p> Saat ini Anda menggunakan <strong>{{ AppName }}</strong> Versi : <strong>5.0</strong>
                    </p></div>
                </div>
            </div>
        </div>
    </div>
</template>
